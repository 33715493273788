import React, { useEffect } from 'react'

import { LanguageContext } from '../Context/LanguageContext';
import  { useContext } from 'react';
import ServiceIcon from '../components/ServiceIcon';

import brand1 from '../assets/icons/brand1.png';
import appicon from '../assets/icons/app_icon.png';

import AOS from 'aos';
import 'aos/dist/aos.css';

function Brands() {
    const { texts } = useContext(LanguageContext);


    function onBrandClick(){

    }

  return (
    <div    id='brands' className='flex flex-col max-w-[1300px]  mt-20 m-auto  items-center justify-center pb-10 text-center  pr-4 pl-4'>
              <h1
        className="text-black font-bold sm:text-3xl text-lg "
        data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
        > {texts.brands} </h1>
       <hr
        className="border-yellow-400 w-20 mt-3 sm:mb-16 mb-7"
        data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
        />
        <h1 className='text-black font-semibold sm:text-lg text-sm mb-14'
          data-aos='fade-down'
        >
         {texts.brands_subtitle}
          </h1>


        <div className='w-full flex   justify-center items-start mt-14 '>
         
          <ServiceIcon text={'Nma Clothes Company Brand'} icon={brand1} link={'https://linktr.ee/kutali_nma'} />


          <ServiceIcon text={'Dizhwar Kawa Kaka Bra Company'} icon={appicon} link={'https://linktr.ee/dizhwar.kawa.company'}/>


        </div>
   </div>
  )
}

export default Brands