import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../Context/LanguageContext';
import icon from '../assets/22.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const AboutSection = () => {
  const { texts  } = useContext(LanguageContext);
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div
      id="about"
      className="flex flex-col max-w-[1300px] m-auto items-center justify-center mt-10 mb-32  pr-4 pl-4 md:text-start text-center "
    >
      <h1
        className="text-black font-bold sm:text-3xl text-lg "
        data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
      >
        {texts.about_us}
      </h1>
      <hr
        className="border-yellow-400 w-20 mt-3 sm:mb-16 mb-7"
        data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
      />

      <div className="w-full md:flex items-center justify-between">
        <div className="md:w-[45%] w-full flex flex-col items-center md:items-start">
          <h1
            className="text-black font-semibold sm:text-lg text-sm"
           data-aos='fade-down'
          >
            {texts.about_section_text}
          </h1>
          <a
            href="/about"
            className="mt-5 bg-yellow-400 flex justify-center items-center text-black font-bold rounded w-56 h-8 hover:bg-gray-700 hover:text-white hover:border-white hover:border"
            data-aos="fade-up" data-aos-anchor-placement="top-center"
          >
            {texts.read_more}
          </a>
        </div>
        <img
          className="md:w-[45%] w-full md:mt-0 mt-16"
          src={icon}
          alt="icon"
          data-aos='fade-down'        />
      </div>
    </div>
  );
};

export default AboutSection;
