import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../Context/LanguageContext';
import AOS from 'aos';
import 'aos/dist/aos.css';


function ServiceIcon({ text, icon, link, subtitle ,description}) {



  const [finalTitle, setFinalTitle] = useState(text); 

  useEffect(()=>{
   if(text=='Nma Clothes'){
     setFinalTitle("Nma Clothes");
   }else if(text=='Dizhwar Kawa Kaka Bra Company'){
     setFinalTitle('Dizhwar Kawa Kaka Bra Company');
   }
  })


  return (
    <div
      className='flex flex-col items-center justify-between mb-5 md:w-60 w-40 cursor-pointer mr-5'
    >
      <img className={`md:w-40 w-20 md:h-40 h-20 `}
        data-aos='fade-down'
      src={icon} alt="icon" />
      <h1 
      className='text-black md:text-xl font-semibold mt-5'
      data-aos='fade-down'
      >{finalTitle}</h1>

<a href={link} target="_blank" className='text-blue-500 mt-5'>Social media & Location</a>

    </div>
  );
}

export default ServiceIcon;
