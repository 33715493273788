
import { Routes, Route,Switch } from 'react-router-dom';
import About from '../pages/About';
import Hero from '../pages/Hero';
import AboutSection from '../pages/AboutSection';
import HomeButton from '../components/HomeButton';
import Brands from '../pages/brands'
import Gallary from '../pages/gallary';
const AppRoutes = () => {
    return (
        <Routes>
            <Route index  path='/' element={<div className='h-full'>
            <Hero/> <AboutSection/> <Brands/> <Gallary/>  <HomeButton/></div>}/>
            <Route path='/about' element={<div className='h-full'><About /></div>} />
        </Routes>
    )
}

export default AppRoutes;